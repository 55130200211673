<template>
	<div class="assessBox">
		<div class="app-container">
			<div class="titlebox">
				<el-button size="medium" type="text" @click="$router.go(-1)">返回</el-button>
				<div class="title">{{ title }}</div>
				<el-button size="mini" style="float: right; font-size: 14px" icon="el-icon-notebook-2" @click="handleViewList">调整记录</el-button>
			</div>
			<div class="formBox" style="margin: 0 120px">
				<formItem ref="formItem" :formItems="formItems" @submit="this.submitForm" @cancel="$router.go(-1)" :defaultData="formModel">
				</formItem>
			</div>
		</div>
		<!-- 导入 -->
		<importmodal @handleSearch="handleSearch" :importUrl="importUrl" :type="type" :dialogImportVisible.sync="dialogImportVisible"></importmodal>
		<!-- <el-dialog
			width="900px"
			:title="dialogTitle"
			top="30vh"
			center
			:visible.sync="dialogVisible"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
			:modal-append-to-body="false"
		>
			<baseTable ref="baseTable" @request="getTableData" :queryItem="queryItem" :columns="tableCols" :queryValues="defaultValues" showOrder />
		</el-dialog> -->
	</div>
</template>
<script>
// import Vue from 'vue';
import formItem from '@/views/components/components-form-item/index.vue';
// import baseTable from '@/views/components/components-base-table';
// import { getUrlParams } from '@/utils/util';
import importmodal from '@/components/import';
import { getUrlParams } from '@/utils/util';
export default {
	components: { formItem, importmodal },
	computed: {},
	data() {
		const requiredInput = { required: true, message: '请输入', trigger: 'blur' };
		const requiredSelect = { required: true, message: '请选择', trigger: 'change' };

		return {
			title: '',
			subLoading: false,
			dataList: [{}],
			dataList2: [{}],
			dialogImportVisible: false,
			// dialogTitle: '调整记录',
			// dialogVisible: false,
			formItems: [
				{
					label: '调整系数',
					key: 'type',
					rules: requiredSelect,
					type: 'select',
					span: 12,
					options: [
						{ dictId: '1', dictName: '公司年度考核系数' },
						{ dictId: '2', dictName: '个人年度考核系数' }
					]
				},
				{
					span: 12,
					render: () => (
						<el-button size="small" type="primary" style="margin-top: 5px" icon="el-icon-upload2" onclick={this.importProfession}>
							一键导入
						</el-button>
					),
					style: 'text-align: right',
					hidden: ({ type }) => !type || type === '1'
				},
				{
					label: '财年',
					key: 'year',
					rules: requiredSelect,
					type: 'year',
					span: 12,
					hidden: ({ type }) => !type
				},
				{
					label: '姓名',
					key: 'staffId',
					showKey: 'staffName',
					rules: requiredSelect,
					type: 'selectSearch',
					span: 12,
					async: (query) =>
						this.$axios
							.post('/v1/stock/ratio/waitEffectStaffList', { request: { staffName: query || '' } })
							.then((v = []) => v.map(({ staffName, staffId }) => ({ dictId: staffId, dictName: staffName }))),
					hidden: ({ type }) => !type || type === '1'
				},
				{
					label: '系数',
					key: 'ratio',
					rules: requiredInput,
					type: 'inputNumber',
					span: 12,
					step: 0.01,
					max: 1,
					min: 0,
					hidden: ({ type }) => !type
				},
				{
					span: 24,
					render: () => (
						<div class="footerText">
							*公司年度考核系数：该系数默认为1，当公司经营达成与目标出现较大差距时，可通过此系数调整该年度所有待行权人的实际行权股数；
							个人年度考核系数：该系数默认为该行权人年度绩效考核系数，当该行权人行权股数无需与个人绩效考核结果挂钩时，可通过此系数进行调整。
						</div>
					),
					style: 'text-align: left'
					// hidden: ({ type }) => !type || type === '1'
				}
			],
			formModel: {},
			importUrl: '/v1/stock/importPersonalRatio',
			type: 'importCompanyRatio' // dictId
		};
	},
	mounted() {
		this.title = '年度行权系数调整';
		const params = getUrlParams(this.$route.query?.params);

		if (params) {
			const data = JSON.parse(params);

			this.formModel = { ...data, type: data.type?.toString(), year: data.year?.toString() };
			// this.title = '年度行权系数调整';
		}
	},
	methods: {
		// // table
		// getTableData(params, callback) {
		// 	callback(this.$axios.post('/v1/stock/ratio/listByPage', params));
		// },
		// 提交
		submitForm(form) {
			form.validate((valid) => {
				if (valid) {
					this.$axios
						.post('/v1/stock/ratio/save', { request: { ...this.formModel } })
						.then(() => {
							this.$router.go(-1);
							this.$message.success('保存成功');
							this.$refs.baseTable?.handleSearch();
						})
						.catch(() => {
							// this.dialogVisible = false;
						});
				}
			});
		},
		// 导入
		importProfession() {
			this.dialogImportVisible = true;
		},
		handleSearch() {
			//   this.getList();
		},
		handleViewList() {
			// this.defaultValues.type = '1';
			// this.$set(this.defaultValues, 'type', '1');
			// this.dialogVisible = true;
			this.$router.push({ path: 'adjustTheRecord' });
		}
	}
};
</script>
<style lang="scss">
.assessBox {
	.app-container {
		color: #484848;
		padding: 0;
		// background-color: #f6f7fb;

		.titlebox {
			text-align: center;
			line-height: 17px;
			background: #fff;
			font: bold 18px/25px 'Microsoft YaHei';
			padding: 14px 40px;
			margin: 20px;
			padding-right: 0;
			> .el-button {
				float: left;
				// color: #484848;
				transform: translateY(-8px);
				font-size: 16px;
			}
		}
	}
	.el-dialog__wrapper {
		.el-dialog.el-dialog--center {
			padding-bottom: 60px;
		}
	}
}

.formBox {
	padding: 40px 20px;
	background: #fff;
	// margin: 20px;
	.el-form-item__content {
		.el-tag {
			margin: 0 10px 10px 0;
		}
		.el-range-separator {
			width: 20px;
		}
	}
}

.form {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;

	.el-form-item {
		width: 40%;

		.el-form-item__content {
			width: 60%;

			.el-select {
				width: 100%;
			}
		}
	}

	.form_footer_item {
		width: 100%;

		.el-form-item__content {
			width: 100%;

			.el-select {
				width: 100%;
			}
		}
	}
}

.form_footer {
	margin-top: 40px;
	display: flex;
	justify-content: center;
	align-items: center;

	.footer_btn {
		cursor: pointer;
		font-size: 14px;
		width: 140px;
		line-height: 40px;
		text-align: center;
		height: 40px;
		border-radius: 6px;
		color: #fff;
	}

	.cancle_btn {
		margin-right: 40px;
		color: #c5cdda;
		border: 1px solid #dcdfe6;
	}

	.enter_btn {
		background-color: #409eff;
	}
}
.formItem {
	.footerText {
		width: 490px;
		color: #999;
		line-height: 21px;
		margin-top: 60px;
	}
}
</style>
